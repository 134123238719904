@use '../includes' as *;

.video_thumbnail_carousel {
  container-type: inline-size;

  [class*='splide__track'] {
    // allows for expanded thumbnail to be visible on hover
    overflow: visible;
  }

  @media (hover: hover) {
    &:has(a[class*='video_mezzanine_link']:hover) {
      a[class*='video_mezzanine_link']:not(:hover) {
        opacity: 0.5;
        background-color: $midnight;
      }
    }
  }
}

button.splide__arrow {
  @include splide-arrow;

  inset-block-start: 21cqw;
  margin-block-start: -30px;

  @include breakpoint($xs) {
    inset-block-start: 18cqw;
  }

  @include breakpoint($sm) {
    inset-block-start: 10.5cqw;
  }

  @include breakpoint($md) {
    inset-block-start: 10cqw;
  }

  @include breakpoint($xl) {
    inset-block-start: 7.5cqw;
  }
}
