@use '../../../components/includes/' as *;

.explore_breadcrumb {
  z-index: 1;
  padding-block-start: $g12;
  padding-block-end: $g20;
  padding-inline-start: var(--spacing-inline);
  pointer-events: none;

  @include body-copy-3($pebble);

  svg {
    width: 4px;
    margin-inline: 0.5em;
    transform: translate(0, -2px);

    circle {
      fill: $pebble;
    }
  }

  @include breakpoint($sm) {
    position: absolute;
    padding-block-start: $g24;
  }

  @include breakpoint($utility-nav-bp) {
    display: none;
  }
}

.explore_breadcrumb__genre {
  color: $fog;
}
