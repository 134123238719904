@use '../includes' as *;

.articles {
  // Defining a container here so the Splide arrow positioning works
  container-type: inline-size;
  margin-block-end: var(--spacing-block);
  padding-inline: var(--spacing-inline);

  [class*='splide__track'] {
    // allows for expanded thumbnail to be visible on hover
    overflow: visible;
  }

  @media (hover: hover) {
    &:has(div[class*='article']:hover) {
      div[class*='article']:not(:hover) {
        opacity: 0.5;
        background-color: $midnight;
      }
    }
  }
}

.articles_title {
  @include h2;

  margin-block-end: clamp($g4, 1.4vw, $g8);
}

.article_thumbnail {
  display: flex;
  container-type: inline-size;
  flex-direction: column;
  gap: clamp($g4, 1.4vw, $g8);
  min-width: 140px;
}

a.article_image_link {
  display: block;
  position: relative;
  aspect-ratio: 16 / 9;
  transition-property: outline-color, opacity;
  transition-duration: var(--duration);
  border-radius: $radius-ml;
  outline: var(--border-thickness) solid transparent;

  @media (hover: hover) {
    &:hover {
      outline-color: $white;
    }
  }
}

.article_image {
  width: 100%;
  border-radius: $radius-ml;
}

.article_over_title {
  @include over-title($pebble);

  @include breakpoint($md) {
    color: $yellow;
    font-size: $f14;
  }
}

.article_headline {
  @include h5;

  @include breakpoint($below-xs) {
    @include typography-truncate(2);
  }

  @include breakpoint($md) {
    // Clamping font to be 16px -> 22px, from vw 1024px -> 1440px
    font-size: clamp($f16, 0.12rem + 1.4vw, $f18);
  }
}

.article_description {
  @include body-copy-3($pebble);

  @include breakpoint($below-xs) {
    @include typography-truncate(2);
  }

  @include breakpoint($sm) {
    color: $pebble;
    font-size: clamp($f14, 0.9rem + 0.5vw, $f16);
  }

  @include breakpoint($md) {
    color: $fog;
  }
}

button.splide__arrow {
  @include splide-arrow;

  inset-block-start: 22cqw;
  margin-block-start: -30px;

  @include breakpoint($xxxs) {
    inset-block-start: 20cqw;
  }

  @include breakpoint($xxs) {
    inset-block-start: 19cqw;
  }

  @include breakpoint($xs) {
    inset-block-start: 18cqw;
  }

  @include breakpoint($sm) {
    inset-block-start: 16.5cqw;
  }

  @include breakpoint($md) {
    inset-block-start: 16cqw;
  }

  @include breakpoint($xl) {
    inset-block-start: 11.5cqw;
  }
}
